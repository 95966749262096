import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import MainLayout from "layouts/Main";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectForecast } from "store/selectors";
import { fetchForecast } from "store/appSlice";
import {
  confirmForecast,
  unConfirmForecast,
  updateForecast,
} from "api/resources/forecasts";
import Filters from "../../Filters";
import {
  Forecast,
  ForecastPeriodConsumption,
  ForecastPeriodData,
  RowValue,
  SelectedHour,
} from "api/models/Forecast";
import ForecastActions from "../../Components/ForecastActions";
import Header from "../../Components/Header";
import ForecastDetails from "../../Components/ForecastDetails";
import MainContent from "../../Components/ForecastMainContent";
import { ProtectedPaths } from "routes";
import { Client } from "api/models/Client";
import { MeteringPoint } from "api/models/MeteringPoint";

interface Day {
  active: boolean;
}

interface FormValues {
  customDates: string[];
  selectedClients: Client[];
  selectedMeteringPointsOptions: MeteringPoint[];
}

interface GroupedCells {
  [date: string]: (ForecastPeriodConsumption | null)[];
}

interface ConsumptionPeriodData {
  date: string;
  day: string;
  readings: ForecastPeriodConsumption[];
}

type CellKey = `${string} ${string}:${string}`;

const Reports = () => {
  const [periodsData, setPeriodsData] = useState<ForecastPeriodData[]>([]);
  const [averageLine, setAverageLine] = useState<number[]>([]);
  const [snackbar, setSnackbar] = useState<{
    type: "success" | "error" | "info";
    message: string;
  } | null>(null);
  const [forecastLine, setForecastLine] = useState<number[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  let { sampleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const forecast = useAppSelector(selectForecast);
  const [formValues, setFormValues] = useState({
    customDates: [],
    selectedClients: [],
    selectedMeteringPointsOptions: [],
  });
  const [tableMatrix, setTableMatrix] = useState<any[]>([]);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    dispatch(fetchForecast(sampleId));
  }, [dispatch, sampleId]);

  useEffect(() => {
    if (forecast) {
      setIsEditable(new Date(forecast.prognoseDate) >= new Date());

      const newFormValues = getUpdatedFormValues(forecast, formValues);
      setFormValues(newFormValues);

      const groupedCells = getGroupedCells(forecast.cellsJson);
      const periodsDataInitial = createPeriodsData(groupedCells);

      setPeriodsData(periodsDataInitial);
    }
  }, [forecast]);

  const getUpdatedFormValues = (forecast: Forecast, formValues: FormValues) => {
    let newFormValues = { ...formValues };
    newFormValues.customDates = forecast.customDates || [];
    newFormValues.selectedClients = forecast.filtersJson[0].clients || [];
    newFormValues.selectedMeteringPointsOptions =
      forecast.filtersJson[0].meteringPoints || [];
    return newFormValues;
  };

  const getGroupedCells = (
    cellsJson: Record<CellKey, ForecastPeriodConsumption>,
  ) => {
    const groupedCells: {
      [key: string]: (ForecastPeriodConsumption | null)[];
    } = {};

    Object.entries(cellsJson).forEach(([key, cell]) => {
      const [date, time] = key.split(" ");
      const hour = parseInt(time.split(":")[0], 10);

      if (!groupedCells[date]) {
        groupedCells[date] = new Array(25).fill(null);
      }

      groupedCells[date][hour] = cell;
    });

    return groupedCells;
  };

  const createPeriodsData = (
    groupedCells: GroupedCells,
  ): ConsumptionPeriodData[] => {
    return Object.entries(groupedCells).map(([date, cells]) => {
      const readings: ForecastPeriodConsumption[] = cells.map((cell, hour) => ({
        datetime: `${date} ${hour.toString().padStart(2, "0")}:00`,
        selected: cell?.selected || false,
        totalConsumption: cell?.totalConsumption || 0,
        isMissingData: cell
          ? cell.meteringPointsWithConsumption < cell.meteringPointsTotal
          : false,
        meteringPointsWithConsumption: cell?.meteringPointsWithConsumption || 0,
        meteringPointsTotal: cell?.meteringPointsTotal || 0,
      }));
      return {
        date: dayjs(date).format("DD.MM.YYYY"),
        day: new Date(date).toLocaleDateString("en-US", { weekday: "long" }),
        readings,
      };
    });
  };

  const handleSubmitFilters = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const forecastData = {
      customDates: formValues.customDates,
      filters: [
        {
          clients: formValues.selectedClients,
          meteringPoints: formValues.selectedMeteringPointsOptions,
        },
      ],
    };

    updateForecast(sampleId, forecastData)
      .then(() => {
        dispatch(fetchForecast(sampleId));
        setSnackbar({
          type: "success",
          message: "Filters saved",
        });
      })
      .catch((error) => {
        console.log({ error });
        setSnackbar({
          type: "error",
          message: "Something went wrong when saving the forecast",
        });
      });
  };

  const handleSaveForecast = () => {
    let rowValues: RowValue[] = [];
    let selectedHours: SelectedHour[] = [];

    setSaving(true);

    tableMatrix.map((row, rowIndex) => {
      row.readings.map((day: Day, dayIndex: number) => {
        const hour = periodsData[dayIndex].readings[rowIndex].datetime;
        selectedHours.push({
          [hour]: day.active || false,
        });
      });

      rowValues.push({
        hour: rowIndex,
        adjustment: Number(row.correction),
        value: row.forecast,
      });
    });

    const forecastData = {
      prognoseValues: rowValues,
      selectedPeriods: selectedHours,
    };

    updateForecast(sampleId, forecastData)
      .then(() => {
        setSaving(false);
        navigate(`/${ProtectedPaths.Consumption}`);
      })
      .catch((error) => {
        console.log({ error });
        setSaving(false);
        setSnackbar({
          type: "error",
          message: "Something went wrong when saving the forecast",
        });
      });
  };

  const handleConfirmForecast = () => {
    confirmForecast(Number(sampleId)).then(() => {
      setSnackbar({
        type: "success",
        message: "Forecast confirmed",
      });
      dispatch(fetchForecast(sampleId));
    });
  };

  const handleUnconfirmForecast = () => {
    unConfirmForecast(sampleId).then(() => {
      setSnackbar({
        type: "info",
        message: "Forecast opened for changes",
      });
      dispatch(fetchForecast(sampleId));
    });
  };

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  if (!forecast) return null;

  return (
    <MainLayout
      tabs={[
        { label: "Manage samples", path: "Consumption" },
        { label: "Saved samples", path: "SavedSamples" },
      ]}
      snackbar={{
        open: !!snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Header type="consumption" forecast={forecast} />
        <ForecastActions
          isEditable={isEditable}
          isVerified={forecast.isVerified}
          handleConfirmForecast={handleConfirmForecast}
          handleSaveForecast={handleSaveForecast}
          handleUnconfirmForecast={handleUnconfirmForecast}
          saving={saving}
        />
      </Box>
      {isEditable && (
        <Filters
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSubmitFilters}
          submitText="Save filters"
          showButtons={true}
          loading={false}
        />
      )}
      <ForecastDetails forecast={forecast} />
      <MainContent
        periodsData={periodsData}
        setPeriodsData={setPeriodsData}
        setAverageLine={setAverageLine}
        setForecastLine={setForecastLine}
        tableMatrix={tableMatrix}
        setTableMatrix={setTableMatrix}
        isEditable={isEditable}
        forecast={forecast}
        averageLine={averageLine}
        forecastLine={forecastLine}
      />
    </MainLayout>
  );
};

export default Reports;
