import { Box } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { indigo, pink } from "@mui/material/colors";
import { periods } from "helpers/periods";
import { ForecastPeriodData } from "../../../../../api/models/Forecast";
import { TableRow } from "./ForecastDataGrid";
import { useEffect, useState } from "react";

interface ForecastLineChartProps {
  periodsData?: ForecastPeriodData[];
  averageLine: number[];
  forecastLine: number[];
  tableMatrix?: TableRow[];
}

interface SeriesData {
  data: number[];
  id: number;
  label: string;
  showMark: boolean;
  color?: string;
}

const xAxisData = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24,
];

const xAxis = [
  {
    data: xAxisData,
    valueFormatter: (value: number) => periods[value],
  },
];

const ForecastLineChart = ({
  periodsData,
  averageLine,
  forecastLine,
  tableMatrix,
}: ForecastLineChartProps) => {
  const [colorMapping, setColorMapping] = useState<Map<string, string>>(
    new Map(),
  );

  const [series, setSeries] = useState<SeriesData[]>([]);

  const brightColors = [
    "#E57373", // Soft Red
    "#81C784", // Soft Green
    "#64B5F6", // Soft Blue
    "#F06292", // Soft Pink
    "#FFB74D", // Soft Orange
    "#4DD0E1", // Soft Cyan
    "#9575CD", // Soft Purple
    "#FFF176", // Soft Yellow
    "#A1887F", // Soft Brown
    "#BA68C8", // Soft Magenta
  ];

  useEffect(() => {
    if (periodsData) {
      const newColorMapping = new Map<string, string>();
      periodsData.forEach((period, index) => {
        if (!colorMapping.has(period.date)) {
          newColorMapping.set(
            period.date,
            brightColors[index % brightColors.length],
          );
        } else {
          newColorMapping.set(period.date, colorMapping.get(period.date)!);
        }
      });
      setColorMapping(newColorMapping);
    }
  }, [periodsData]);

  const isCheckboxChecked = (cellIndex: number) => {
    return tableMatrix?.some(
      (row: TableRow) => row.readings[cellIndex]?.active,
    );
  };

  useEffect(() => {
    const filteredPeriods =
      periodsData?.filter((_, periodIndex) => isCheckboxChecked(periodIndex)) ||
      [];
    const initialSeries = filteredPeriods.map((period, periodIndex) => ({
      data: period.readings.map((reading) => reading.totalConsumption),
      id: periodIndex,
      label: period.date,
      showMark: false,
      color: colorMapping.get(period.date),
    }));

    const uniqueIdStart = initialSeries.length;

    initialSeries.push({
      data: averageLine,
      id: uniqueIdStart,
      label: "Average",
      color: indigo[900],
      showMark: false,
    });

    initialSeries.push({
      data: forecastLine,
      id: uniqueIdStart + 1,
      label: "Forecast",
      color: pink.A400,
      showMark: false,
    });

    setSeries(initialSeries);
  }, [periodsData, tableMatrix, averageLine, forecastLine, colorMapping]);

  const allData = [
    ...series.flatMap((s) => s.data),
    ...averageLine,
    ...forecastLine,
  ];
  const calculatedMin = Math.min(...allData);
  const calculatedMax = Math.max(...allData);

  const yAxis = [
    {
      min: calculatedMin - 0.1,
      max: calculatedMax + 0.1,
      valueFormatter: (value: number) => value.toString(),
    },
  ];

  return (
    <Box>
      <LineChart
        xAxis={xAxis}
        yAxis={yAxis}
        series={series}
        width={530}
        height={380}
        grid={{ vertical: true, horizontal: true }}
        margin={{ left: 40, right: 40, top: 40, bottom: 90 }}
        colors={[
          "#fbb4ae",
          "#b3cde3",
          "#ccebc5",
          "#decbe4",
          "#fed9a6",
          "#ffffcc",
          "#e5d8bd",
          "#fddaec",
          "#f2f2f2",
        ]}
        slotProps={{
          legend: {
            position: {
              vertical: "bottom",
              horizontal: "middle",
            },
            itemMarkWidth: 15,
            itemMarkHeight: 2,
            markGap: 5,
            itemGap: 5,
          },
        }}
        sx={{
          "& .MuiChartsLegend-series text": { fontSize: "12px !important" },
        }}
      />
    </Box>
  );
};

export default ForecastLineChart;
