import axiosClient from "../axiosInstance";

export const sendExcelToLitgrid = async (file: File) => {
  const client = axiosClient();

  const response = await client.post(
    `/litgrid/send`,
    {
      file,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data;
};

export const downloadLitgridExcel = async (file: File) => {
  const client = axiosClient();

  const response = await client.post(
    `/litgrid/upload`,
    {
      file,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data;
};
